import React, { useEffect, useState } from "react";
import logo from "./assets/logo.png";
import logoWhite from "./assets/logo white.png";
import homePicture from "./assets/Group 816.png";
import createIcon from "./assets/icons/Union.svg";
import collaborateIcon from "./assets/icons/Vector.svg";
import experienceIcon from "./assets/icons/Unionpe.svg";
import {
  useLocation,
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import TermsAndConditionsModal from "./pages/TermsAndConditions";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
      </Routes>
    </BrowserRouter>
  );
}
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
const HomePage = () => {
  const query = useQuery();
  const queryIsModalOpen = query.get("isModalOpen");
  const queryIsTermsAndCondition = query.get("isTermsAndCondition");
  const [isModalOpen, setIsModalOpen] = useState(queryIsModalOpen || false);
  const [isTermsAndCondition, setIsTermsAndCondition] = useState(
    queryIsTermsAndCondition || false
  );
  console.log(
    "----------------------------------------------------------------",
    queryIsModalOpen,
    queryIsTermsAndCondition
  );
  const openModal = (termsAndCondition: boolean) => {
    setIsTermsAndCondition(termsAndCondition);
    setIsModalOpen(true);
  };
  const navigate = useNavigate();

  const closeModal = () => {
    setIsModalOpen(false);
    navigate("/");
  };

  return (
    <>
      <div className="w-full min-h-screen bg-slate-200">
        <div className="w-full px-8 md:px-32 py-4 md:py-10 bg-white flex flex-col md:flex-row justify-between items-center md:h-1/6">
          <img src={logo} className="h-10 md:h-20" alt="logo" />
          <nav className="flex justify-center space-x-4 mt-4 md:mt-0">
            <a
              href="/#"
              className="font-bold px-4 md:px-8 py-2 md:py-3 text-slate-700 rounded-lg hover:bg-accent hover:text-slate-900"
            >
              Home
            </a>
            <a
              href="/#"
              className="font-bold px-4 md:px-8 py-2 md:py-3 text-slate-700 rounded-lg hover:bg-accent hover:text-slate-900"
            >
              About Us
            </a>
            <a
              href="/#"
              className="font-bold px-4 md:px-8 py-2 md:py-3 text-slate-700 rounded-lg hover:bg-accent hover:text-slate-900"
            >
              Help
            </a>
          </nav>
        </div>
        <div className="w-full px-8 md:px-32 py-4 md:py-10 bg-accent flex flex-col md:flex-row justify-between items-center md:h-4/6 text-center md:text-left">
          <div className="w-full md:w-3/5 h-full flex flex-col items-center md:items-start justify-center gap-6 md:gap-12">
            <h1 className="text-dark mb-2 text-4xl md:text-6xl font-semibold text-black">
              Find or create{" "}
              <span className="text-secondary">your events with OutApp!</span>
            </h1>
            <p className="text-base md:text-lg">
              Efficiently collaborate to organize or find your creative events!
            </p>
            <button className="bg-primary border-primary border inline-flex items-center justify-center py-2 md:py-3 px-5 md:px-7 text-center text-base font-medium text-white hover:bg-secondary hover:border-secondary">
              Download the App
            </button>
          </div>
          <img
            src={homePicture}
            alt="home"
            className="w-full md:w-2/5 mt-6 md:mt-0"
          />
        </div>
        <div className="w-full px-8 md:px-32 py-4 md:py-10 bg-white flex flex-col md:flex-row justify-around items-center text-center">
          <div className="p-2 w-24 md:w-44 h-24 md:h-44 flex flex-col justify-center items-center gap-4 md:gap-6">
            <img src={createIcon} alt="create" className="w-10 md:w-14" />
            <p className="text-sm md:text-base">Create or Find</p>
          </div>
          <div className="p-2 w-24 md:w-44 h-24 md:h-44 flex flex-col justify-center items-center gap-4 md:gap-6">
            <img
              src={collaborateIcon}
              alt="collaborate"
              className="w-10 md:w-14"
            />
            <p className="text-sm md:text-base">Collaborate</p>
          </div>
          <div className="p-2 w-24 md:w-44 h-24 md:h-44 flex flex-col justify-center items-center gap-4 md:gap-6">
            <img
              src={experienceIcon}
              alt="experience"
              className="w-10 md:w-14"
            />
            <p className="text-sm md:text-base">Experience</p>
          </div>
        </div>
        <div className="w-full px-8 md:px-32 py-4 md:py-10 bg-primary flex flex-col md:flex-row justify-between items-start text-white text-center md:text-left">
          <img src={logoWhite} className="h-10 md:h-20" alt="logo" />
          <div className="w-full md:w-1/4 px-4 mt-4 md:mt-0">
            <h1 className="text-base md:text-lg font-semibold">Our Contacts</h1>
            <p className="text-sm md:text-base">info@outapp.app</p>
          </div>
          <div className="w-full md:w-1/4 px-4 mt-4 md:mt-0">
            <a href="mailto:info@outapp.app" className="text-base md:text-lg">
              Connect with us
            </a>
          </div>
          <div className="w-full md:w-1/4 px-4 mt-4 md:mt-0">
            <p
              className="text-base md:text-lg"
              onClick={() => {
                openModal(false);
              }}
            >
              Privacy Policies
            </p>
          </div>
          <div className="w-full md:w-1/4 px-4 mt-4 md:mt-0">
            <p className="text-base md:text-lg" onClick={() => openModal(true)}>
              Terms and Conditions
            </p>
          </div>
          <button
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            className="flex items-center justify-center w-10 h-10 bg-white hover:bg-white text-primary rounded-full mt-4 md:mt-0"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 5a1 1 0 01.707.293l5 5a1 1 0 01-1.414 1.414L11 8.414V15a1 1 0 11-2 0V8.414L5.707 11.707a1 1 0 01-1.414-1.414l5-5A1 1 0 0110 5z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        <div className="w-full bg-primary flex items-center justify-center py-4 text-accent text-xs">
          <p>All rights reserved OutApp</p>
        </div>
      </div>
      <TermsAndConditionsModal
        isOpen={isModalOpen}
        onClose={closeModal}
        isTermsAndCondition={isTermsAndCondition}
      />
    </>
  );
};

export default App;
