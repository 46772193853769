import React from "react";

const Modal = (props: any) => {
  if (!props?.isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/3">
        {props?.isTermsAndCondition && (
          <h2 className="text-2xl font-bold mb-4">Terms and Conditions</h2>
        )}
        {!props?.isTermsAndCondition && (
          <h2 className="text-2xl font-bold mb-4">Privacy Policies</h2>
        )}
        {props?.isTermsAndCondition && (
          <iframe
            width="100%"
            height="600"
            frameBorder="0"
            title="Our Product"
            src="https://docs.google.com/document/d/e/2PACX-1vRGuQnWA3rqJgsi5hUoP4ukUY2rvS4ynX1YK78H4lxho-X2YqZTNPGx9x6nfSorA3kT9MoL3eahodPk/pub?embedded=true"
          ></iframe>
        )}
        {!props?.isTermsAndCondition && (
          <iframe
            width="100%"
            height="600"
            title="Terms and Conditions"
            src="https://docs.google.com/document/d/e/2PACX-1vTHMron7UNAIxoWivLd-in8MapOZqa6xCLpoNyufmGuRtsMYdvt6yAyRDDe3CConEcucmzkTZVl9qsb/pub?embedded=true"
          ></iframe>
        )}
        <button
          onClick={props?.onClose}
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default Modal;
